export const ro = {
  travelSafety: "Siguranța călătoriei",
  health: "Sănătate",
  remember: "Ține minte",
  summary: "Rezumat",
  vaccination: "Vaccinare",
  advisory: "Consultativ",
  readMore: "Citește mai mult.",
  covid19: "COVID-19",
  updated: "Actualizat",
  total: "Total",
  yesterday: "Ieri",
  cases: "Cazuri",
  deaths: "Decese",
  recovered: "Recuperări",
  casesPerMillion: "Cazuri pe milion",
  testsPerMillion: "Teste pe milion",
  population: "Populația",
  checklist: "Listă",
  importInApp: "Importați în aplicație",
  advisoryDescription: "Se pot aplica restricții de călătorie din cauza pandemiei COVID-19.",
  noCovidData: "Nu există date disponibile în acest moment.",
  infoForTravelersTo: "Informații pentru persoanele care călătoresc în [COUNTRY_NAME]",
  enjoyHolidayWith: "Bucurați-vă de vacanță cu ",
  readySetHoliday: "Pe locuri, fiți gata, concediu!",
  serverErrorText: "Ne pare rău, a apărut o eroare la accesarea acestor informații.",
  notFoundText: "Ne pare rău, informațiile pe care le-ați solicitat nu au fost găsite.",

  // new texts
  enterValue: "Introduceți valoarea",
  exchangeRate: "Curs de schimb",
  // Tab 1 Good to Know
  goodToKnow: "Bine de știut",
  countryInfo: "Informații despre țară",
  visaAndEntry: "Viză și intrare",
  officialInformation: "Informații oficiale",
  officialInfoText: "Verificați site-ul web oficial al turismului guvernamental pentru ghidurile de călătorie actuale.",
  currency: "Monedă",
  localCurrency: "Moneda locală este ",
  emergencyServices: "Servicii de urgență",
  electricity: "Electricitate",
  transport: "Transport",
  safety: "Siguranță",
  internetAndConnectivity: "Internet și conectivitate",
  timeZone: "Fus orar",
  language: "Limba",
  localLawsAndRegulation: "Legi și reglementări locale",

  //Tab 2 Health
  waterQuality: "Calitatea apei",
  vaccinations: "Vaccinare",
  insurance: "Asigurare",
  insuranceText: "Se recomandă încheierea unei asigurări de călătorie pentru a acoperi eventualele cheltuieli medicale neașteptate sau întreruperi de călătorie.",
  medicalChecklist: "Listă de verificare medicală",

  // Tab 2 Cultural
  cultural: "Cultural",
  culturalNorms: "Norme culturale",
  shopping: "Cumpărături",
  prices: "Prețuri",
  tipping: "Bacșiș",
  localCuisine: "Bucătărie locală",
  weather: "Vreme",
  location: "Locație",
  
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}