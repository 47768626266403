// Desc: Constants for the navigation tabs
// name = name of the tab; must be the exact translation key in the translation file
// routeName = route name of the tab;
export const tabs = {
  goodToKnow: {
    name: "goodToKnow",
    routeName: "goodtoknow"
  },
  health: {
    name: "health",
    routeName: "health"
  },
  cultural: {
    name: "cultural",
    routeName: "cultural"
  }
}