export class CurrencyModel {
  homeRate = 0;
  homeCurrency = "";
  destinationRate = 0;
  destinationCurrency = "";
  timeStamp = "";

  constructor(response) {
    let hC = window.sessionStorage.getItem("homeCurrency");
    let dC = window.sessionStorage.getItem("destCurrency");

    this.homeRate = response.rates[hC];
    this.homeCurrency = hC;
    this.destinationRate = response.rates[dC];
    this.destinationCurrency = dC;
    this.timeStamp = response.timestamp;
  }

}