export const hu = {
  travelSafety: "Utazásbiztonság",
  health: "Egészség",
  remember: "Ne feledje",
  summary: "Összegzés",
  vaccination: "Oltás",
  advisory: "Tanácsadó",
  readMore: "Olvass tovább.",
  covid19: "COVID-19",
  updated: "Frissítve",
  total: "Összes",
  yesterday: "Tegnap",
  cases: "Esetek",
  deaths: "Halálozások",
  recovered: "Gyógyultak",
  casesPerMillion: "Esetek milliónként",
  testsPerMillion: "Tesztek milliónként",
  population: "Népesség",
  checklist: "Teendő lista",
  importInApp: "Importálás az alkalmazásban",
  advisoryDescription: "Utazási korlátozások lehetnek érvényben a COVID-19 járvány miatt.",
  noCovidData: "Jelenleg nem áll rendelkezésre adat.",
  infoForTravelersTo: "Információ a következő országba utazók számára: [COUNTRY_NAME].",
  enjoyHolidayWith: "Élvezze nyaralását a ",
  withApp: "-val!",
  readySetHoliday: "\"Vigyázz, kész, utazás!\"",
  serverErrorText: "Sajnáljuk, hiba történt az információk elérése során",
  notFoundText: "Sajnáljuk, a keresett információ nem található.",

  // new texts
  enterValue: "Érték megadása",
  exchangeRate: "Árfolyam",
  // Tab 1 Good to Know
  goodToKnow: "Jó tudni",
  whenToVisit: "Mikor látogasson el",
  visaAndEntry: "Vízum és belépési követelmények",
  officialInformation: "Hivatalos információ",
  officialInfoText: "Ellenőrizze az aktuális utazási irányelveket az állami turisztikai honlapon.",
  currency: "Pénznem",
  localCurrency: "A helyi pénznem ",
  emergencyServices: "Vészhelyzeti szolgáltatások",
  electricity: "Villamos energia",
  transport: "Közlekedés",
  safety: "Biztonság",
  internetAndConnectivity: "Internet és kapcsolat",
  timeZone: "Időzóna",
  language: "Nyelv",
  localLawsAndRegulation: "Helyi törvények és szabályozások",

  //Tab 2 Health
  waterQuality: "Vízminőség",
  vaccinations: "Oltások",
  insurance: "Biztosítás",
  insuranceText: "Ajánlott utazási biztosítást kötni az esetleges váratlan orvosi költségek vagy az utazás megszakításának fedezésére.",
  medicalChecklist: "Orvosi ellenőrző lista",

  // Tab 3 Cultural
  cultural: "Kulturális",
  culturalNorms: "Kulturális normák",
  shopping: "Vásárlás",
  prices: "Árak",
  tipping: "Borravaló",
  localCuisine: "Helyi konyha",
  weather: "Időjárás",
  location: "Helyszín",
  
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}