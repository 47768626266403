import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Section, CurrencyExchange } from "../../components";
import { analyticsService } from "../../services/AnalyticsService";
import { getPageData } from "./pageData";

/**
 * Good to know tab
 */
export function GoodToKnow({ countryDataDetails, countryData }) {
  const { t } = useTranslation('translation');

  useEffect(() => {
    //analytics here
    if (process.env.REACT_APP_ENV === "prod") {
      analyticsService().trackPage("websiteVisit", {
        fullUrl: window.location.href,
        pageName: "GoodToKnow page - GoodToKnow tab"
      });
    }
  }, []);

  return (
    <div>
      <span className="spam-40" />
      {
        getPageData(t, countryDataDetails, countryData).map((item, index) => {
          return (item.visibility &&
            <React.Fragment>
              {item.type === "currency-exchange" ?
                <CurrencyExchange
                  key={index}
                  iconName={item.icon}
                  text={item.text}
                  title={item.title} />
                :
                <React.Fragment>
                  <Section
                    key={index}
                    iconName={item.icon}
                    title={item.title}
                    text={item.text}
                    image={item.image}
                    readMoreUrl={item.readMoreUrl}
                  />
                  <span className="spam-40" />
                </React.Fragment>
              }
            </React.Fragment>
          )
        })
      }
    </div>
  )
}


