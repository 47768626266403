import * as React from "react";
import "./section.css";
import { SectionTitle } from "./SectionTitle"
import { SectionText } from "./SectionText"
import { PlugType } from "../plugType/PlugType";

export type SectionProps = {
  title: String,
  iconName: any,
  text: String,
  readMoreUrl: String,
  image: String | String[],
}

export const Section = (props: SectionProps) => {

  const getImage = () => {
    //check if props.image is an array
    if (Array.isArray(props.image)) {
      return <PlugType images={props.image}/>
    } else if (props.image) {
      return <img className="section-image" src={props.image} alt="" />
    } else {
      return null
    }
  }

  return (
    <div className="section">
      <SectionTitle
        title={props.title}
        iconName={props.iconName}
      />
      {props.text ?
          <SectionText
            text={props.text}
            readMoreUrl={props.readMoreUrl}
          />
        : null
      }
      {
        getImage()
      }
    </div>
  )
}