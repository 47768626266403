import { en_healthChecklist } from "./en_healthChecklist";

export const en = {
  travelSafety: "Travel Safety",
  health: "Health",
  remember: "Remember",
  summary: "Summary",
  vaccination: "Vaccination",
  advisory: "Advisory",
  readMore: "Read more.",
  covid19: "COVID-19",
  updated: "Updated",
  total: "Total",
  yesterday: "Yesterday",
  cases: "Cases",
  deaths: "Deaths",
  recovered: "Recovered",
  casesPerMillion: "Cases per million",
  testsPerMillion: "Tests per million",
  population: "Population",
  checklist: "Checklist",
  importInApp: "Import in app",
  advisoryDescription: "Travel restrictions may apply due to the COVID-19 pandemic.",
  noCovidData: "No data available at this time.",
  infoForTravelersTo: "Information for people traveling to [COUNTRY_NAME].",
  enjoyHolidayWith: "Enjoy your holiday with ",
  readySetHoliday: "Ready Set Holiday!",
  serverErrorText: "We are sorry, there was an error accessing this information.",
  notFoundText: "We are sorry, the information you requested was not found.",
  healthChecklist: en_healthChecklist,

  // new texts
  enterValue: "Enter value",
  exchangeRate: "Exchange Rate",
  // Tab 1 Good to Know
  goodToKnow: "Good to Know",
  whenToVisit: "When to Visit",
  visaAndEntry: "Visa & Entry Requirements",
  officialInformation: "Official Information",
  officialInfoText: "Check the official government tourism website for current travel guidelines.",
  currency: "Currency",
  localCurrency: "Local currency is ",
  emergencyServices: "Emergency Services",
  electricity: "Electricity",
  transport: "Transport",
  safety: "Safety",
  internetAndConnectivity: "Internet & Connectivity",
  timeZone: "Time Zone",
  language: "Language",
  localLawsAndRegulation: "Local Laws and Regulation",

  //Tab 2 Health
  waterQuality: "Water Quality",
  vaccinations: "Vaccinations",
  insurance: "Insurance",
  insuranceText: "Travel insurance is recommended to cover any unexpected medical expenses or trip disruptions.",
  medicalChecklist: "Medical Checklist",

  // Tab 3 Cultural
  cultural: "Cultural",
  culturalNorms: "Cultural Norms",
  shopping: "Shopping",
  prices: "Prices",
  tipping: "Tipping",
  localCuisine: "Local Cuisine",
  weather: "Weather",
  location: "Location",


  
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}