import "./navigation.css";
import {
  Link,
} from "react-router-dom";

type TabProps = {
  name: String,
  url: String,
  routeName: String,
  isEnabled: Boolean
}
export const Tab = (props: TabProps) => {
  let isActive = window.location.href.includes(props.routeName)

  return (
    <div className={props.isEnabled ? "nav-tab-container" : ""}>
      {props.isEnabled ?
        <Link to={`${props.url}/${props.routeName}`} className={isActive ? "nav-tab-text-active h4-bold" : "nav-tab-text-inactive h4-regular"}>
          {props.name}
        </Link>
        :
        <div className="nav-tab-text-disabled">{props.name}</div>
      }
      {isActive ? <div className="nav-tab-selected-indicator" /> : null}
    </div>
  )
}