import { keys } from "../../models/CountryData";

export const getPageData = (t, data, countryData) => {
  return [
    {
      title: t("summary"),
      text: data ? data[keys.healthSummary] : null,
      icon: "first-aid",
      visibility: data ? !!data[keys.healthSummary] : false,
      readMoreUrl: countryData ? countryData["official_health_URL"] : null,
    },
    {
      title: t("waterQuality"),
      text: data ? data[keys.water] : null,
      icon: "drop",
      visibility: data ? !!data[keys.water] : false,
    },
    {
      title: t("vaccinations"),
      text: data ? data[keys.vaccination] : null,
      icon: "syringe",
      visibility: data ? !!data[keys.vaccination] : false,
      readMoreUrl: countryData ? countryData["official_health_URL"] : null,
    },
    {
      title: t("insurance"),
      text: data ? data[keys.insurance] : t("insuranceText"),
      icon: "heartbeat",
      visibility: true
    },
  ]
}