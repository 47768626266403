import React, { useState } from "react";
import "./currencyExchange.css";
import { SectionTitle } from "../section/SectionTitle"
import { SectionText } from "../section/SectionText"
import CurrencyInput from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';
import { convert } from "../../helpers/CurrencyCalculator";
import CurrencyList from 'currency-list'

export type CurrencyExchangeProps = {
  title: String,
  text: String,
  iconName: any,
}

export const CurrencyExchange = (props: CurrencyExchangeProps) => {
  const { t, i18n } = useTranslation('translation');
  const [convertedValue, setConvertedValue] = useState(0);

  let destCurrency = window.sessionStorage.getItem("destCurrency");
  let homeCurrency = window.sessionStorage.getItem("homeCurrency");
  let currencyModel = JSON.parse(window.sessionStorage.getItem("currency"));
  let langTag = window.sessionStorage.getItem("langTag");

  const getDestinationCurrencyDetails = () => {
    try {
      return CurrencyList.get(destCurrency, i18n.language)
    } catch (error) {
      return CurrencyList.get(destCurrency, "en")
    }
  }

  let currencyDetails = getDestinationCurrencyDetails();
  let isSameCurrency = destCurrency === homeCurrency;

  const getValueInHomeCurrency = () => {
    let value = convert(1).toFixed(2);
    if (value == 0.00) {
      return formatValue(convert(1000).toFixed(2));
    }
    return formatValue(value);
  }

  const getInitValueInDestCurrency = () => {
    let value = convert(1).toFixed(2);
    if (value == 0.00) {
      return 1000;
    }
    return 1;
  }

  const getText = () => {
    return (`${t("localCurrency")} <b>${capitalizeFirstLetter(currencyDetails.name)} (${destCurrency})</b> <br/>`) +
      (isSameCurrency ? "" : `${t("exchangeRate")} <b>${getInitValueInDestCurrency()} ${destCurrency} = ${getValueInHomeCurrency()} ${homeCurrency}</b>`);
  }

  const calculateToHomeCurrency = (value: number) => {
    return formatValue(convert(value).toFixed(2));
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const formatValue = (value) => {
    try {
      return new Intl.NumberFormat(i18n.language).format(Number(value));
    } catch (error) {
      //fallback to english
      return new Intl.NumberFormat("en").format(Number(value));
    }
  }

  return (
    (homeCurrency && currencyModel?.destinationCurrency) ?
      <React.Fragment>
        <div className="currency section">
          <SectionTitle
            title={props.title}
            iconName={props.iconName}
          />
          <SectionText
            text={getText()}
          />
          {!isSameCurrency &&
            <div className="currency-calc-row">
              <div className="currency-calc-content">
                <CurrencyInput
                  id="input-example"
                  name="input-name"
                  intlConfig={{ locale: 'de-DE' }}
                  className="currency-calc-input input body-large-regular"
                  placeholder={t("enterValue")}
                  decimalsLimit={-1}
                  allowNegativeValue={false}
                  onValueChange={(value, name, values) => setConvertedValue(calculateToHomeCurrency(value || 0))}
                />
              </div>
              <i className="currency-icon ph ph-equals" />
              <div className="currency-calc-content body-large-regular">{`${convertedValue} ${homeCurrency}`}</div>
            </div>
          }
          <div className="currency-symbol">{currencyDetails.symbol}</div>
        </div>
        <span className="spam-40" />
      </React.Fragment>
      : null
  )
}