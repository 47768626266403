import * as React from "react";
import "./plugType.css";

import typeA from "../../resources/images/plugTypes/type_A.png";
import typeB from "../../resources/images/plugTypes/type_B.png";
import typeC from "../../resources/images/plugTypes/type_C.png";
import typeD from "../../resources/images/plugTypes/type_D.png";
import typeE from "../../resources/images/plugTypes/type_E.png";
import typeF from "../../resources/images/plugTypes/type_F.png";
import typeG from "../../resources/images/plugTypes/type_G.png";
import typeH from "../../resources/images/plugTypes/type_H.png";
import typeI from "../../resources/images/plugTypes/type_I.png";
import typeJ from "../../resources/images/plugTypes/type_J.png";
import typeK from "../../resources/images/plugTypes/type_K.png";
import typeL from "../../resources/images/plugTypes/type_L.png";

export type PlugTypeProps = {
  images: String[]
}

export const PlugType = (props: PlugTypeProps) => {
 
  const getPlugTypeImage = (item: String) => {
    switch (item) {
      case "typeA": return typeA;
      case "typeB": return typeB;
      case "typeC": return typeC;
      case "typeD": return typeD;
      case "typeE": return typeE;
      case "typeF": return typeF;
      case "typeG": return typeG;
      case "typeH": return typeH;
      case "typeI": return typeI;
      case "typeJ": return typeJ;
      case "typeK": return typeK;
      case "typeL": return typeL;
      default: return null;
    }
  }

  return (
    <div className="pt-image-list">
      {props.images.map((item, index) => {
        return <img className="pt-image" src={getPlugTypeImage(item)} alt="" />
      })}
    </div>
  )
}