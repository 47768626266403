import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Switch,
  Route,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Health } from "./health/Health";
import { CovidManager } from "../manager/CovidManager";
import { FirebaseService } from '../services/FirebaseService';
import { Cultural } from './cultural/Cultural';
import { tabs } from '../components/navigationTab/TabConstants';
import { GoodToKnow } from './goodToKnow/GoodToKnow';
import { Spinner, NavigationTab } from '../components';
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import queryString from 'query-string';

export function TravelSafety() {
  const { t, i18n } = useTranslation('translation');
  const [covidState, setCovidState] = useState({})
  const [firebaseState, setFirebaseState] = useState({ isFinished: false })
  const [countryDataDetails, setCountryDataDetails] = useState({ isFinished: false, isCurrencyFinihsed: false })
  let { path, url } = useRouteMatch();

  useEffect(() => {
    //save all needed data to session storage from query
    let homeCurrency = queryString.parse(window.location.search).homeCurrency
    let langTag = queryString.parse(window.location.search).langTag

    if (homeCurrency) {
      window.sessionStorage.setItem("homeCurrency", homeCurrency.toUpperCase())
    }

    if (langTag) {
       window.sessionStorage.setItem("langTag", langTag)
    }

    async function fetchData() {
      let regEx = /^5[0-9][0-9]$/
      let iso = getCountryIso();
      let covidManager = new CovidManager();
      let firebaseService = new FirebaseService();

      let covidResponse = covidManager.getCovidDataByCountry(iso)
        .then((resp) => {
          setCovidState({ ...covidState, isFinished: true, data: resp })
        });

      let firebaseResponse = firebaseService.getDataByCountry(iso)
        .then((resp) => {
          setFirebaseState({ ...firebaseState, isFinished: true, data: resp.response })
          window.sessionStorage.setItem("destCurrency", resp.response["currency_code"].toUpperCase())
        });

      //Fetch currency information; save to session storage; response containes only relevant data
      let currency = firebaseService.getCurrencies()
      .then((resp) => {
        window.sessionStorage.setItem("currency", JSON.stringify(resp.response))
      });

      //fetch country details
      let countryDataDetailsResponse = firebaseService.getDetailsByCountryAndLanguage(iso, i18n.language)
        .then((resp) => {
          setCountryDataDetails({ ...countryDataDetails, isFinished: true, isCurrencyFinihsed: false, data: resp.response })
        });

      //Disease API
      if (regEx.test(covidResponse.statusCode)) {
        window.location.href = `${window.location.origin}/${i18n.language}/error`
      }

      //Firebase
      if (regEx.test(firebaseResponse.statusCode)) {
        window.location.href = `${window.location.origin}/${i18n.language}/error`
      } 
      if (regEx.test(countryDataDetailsResponse.statusCode)) {
        window.location.href = `${window.location.origin}/${i18n.language}/error`
      }
      if (regEx.test(currency.statusCode)) {
        window.location.href = `${window.location.origin}/${i18n.language}/error`
      }
    }

    fetchData();
  }, []);

  function getCountryIso() {
    let countryIso = queryString.parse(window.location.search).iso
    if (countryIso)
      window.sessionStorage.setItem("countryIso", countryIso)
    return countryIso ?? window.sessionStorage.getItem("countryIso", countryIso) ?? "";
  }

  function getCountryName() {
    let countryName = queryString.parse(window.location.search).name
    if (countryName)
      window.sessionStorage.setItem("countryName", countryName)
    return countryName ?? window.sessionStorage.getItem("countryName", countryName) ?? undefined;
  }

  function getPageTitle() {
    let iso = getCountryIso();
    return `${getCountryName()} ${getUnicodeFlagIcon(iso)}`;
  }

  return (
    <div className="travelSafety-body">
      <div className="general-title">{getPageTitle()}</div>
      {getCountryName() ?
        <div className="central-text">
          <div className="text-regular-16">{t("infoForTravelersTo").replace("[COUNTRY_NAME]", getCountryName())}</div>
        </div> : null
      }
      <span className="spam-40" />
      {!(firebaseState.isFinished && countryDataDetails.isFinished) ? <Spinner className={"travel-safety-loading"} /> :
        <div>
          <NavigationTab
            url={url}
            validCultureData={countryDataDetails.data ? countryDataDetails.data.cultural.length : false} />
          <Switch>
            <Route exact path={path}>
            </Route>
            <Route path={`${path}/:topicId`}>
              <Topic
                isFinishedCovid={covidState.isFinished}
                covidData={covidState.data}
                countryData={firebaseState.data}
                countryDataDetails={countryDataDetails} />
            </Route>
          </Switch>
        </div>
      }
    </div>
  )
}

function Topic({ isFinishedCovid, covidData, countryData, countryDataDetails }) {
  let { topicId } = useParams();
  switch (topicId) {
    case tabs.health.routeName:
      return <Health
        isFinishedCovid={isFinishedCovid}
        covidData={covidData}
        countryData={countryData}
        countryDataDetails={!!countryDataDetails.data && countryDataDetails.data.health}
      />;
    case tabs.goodToKnow.routeName:
      return <GoodToKnow
        countryDataDetails={!!countryDataDetails.data && countryDataDetails.data.goodToKnow}
        countryData={countryData}
      />;
    case tabs.cultural.routeName:
      return <Cultural
        countryData={!!countryDataDetails.data && countryDataDetails.data.cultural}
      />;
    default:
      return <Health
        isFinishedCovid={isFinishedCovid}
        covidData={covidData}
        countryData={countryData}
        countryDataDetails={!!countryDataDetails.data && countryDataDetails.data.health} />;
  }
}

