export const zh = {
  travelSafety: "旅行安全",
  health: "健康",
  remember: "记得",
  summary: "概要",
  vaccination: "预防接种",
  advisory: "咨询",
  readMore: "阅读更多。",
  covid19: "新冠肺炎",
  updated: "更新",
  total: "总",
  yesterday: "昨天",
  cases: "案例",
  deaths: "死亡人数",
  recovered: "已恢复",
  casesPerMillion: "百万例",
  testsPerMillion: "每百万测试",
  population: "人口",
  checklist: "检查清单",
  importInApp: "导入应用",
  advisoryDescription: "由于COVID-19大流行，旅行限制可能适用。",
  noCovidData: "目前没有可用数据。",
  infoForTravelersTo: "前往以下国家/地区的人们的信息：[COUNTRY_NAME]",
  enjoyHolidayWith: "享受您的假期 ",
  readySetHoliday: "度假万事备",
  serverErrorText: "很抱歉，访问此信息时出错。",
  notFoundText: "很抱歉，找不到您要求的信息。",

  // new texts
  enterValue: "输入值",
  exchangeRate: "汇率",
  // Tab 1 Good to Know
  goodToKnow: "好知道",
  whenToVisit: "何时参观",
  visaAndEntry: "签证和入境要求",
  officialInformation: "官方信息",
  officialInfoText: "查看政府旅游官方网站以获取当前旅行指南。",
  currency: "货币",
  localCurrency: "当地货币是",
  emergencyServices: "紧急服务",
  electricity: "电力",
  transport: "运输",
  safety: "安全",
  internetAndConnectivity: "互联网和连接",
  timeZone: "时区",
  language: "语言",
  localLawsAndRegulation: "当地法律和法规",

  //Tab 2 Health
  waterQuality: "水质",
  vaccinations: "疫苗接种",
  insurance: "保险",
  insuranceText: "建议购买旅行保险以支付任何意外医疗费用或旅行中断。",
  medicalChecklist: "医疗清单",

  // Tab 3 Cultural
  cultural: "文化",
  culturalNorms: "文化规范",
  shopping: "购物",
  prices: "价格",
  tipping: "小费",
  localCuisine: "当地美食",
  weather: "天气",
  location: "位置",
  
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}