
import { database } from '../firebase';
import { CurrencyModel } from '../models/CurrencyModel';
import { CountryData } from '../models/CountryData';

export class FirebaseService {

  async getDataByCountry(countryIso) {
    let iso = String(countryIso).toUpperCase()
    return database.ref(`/countryData/${iso}`).once("value").then((snapshot) => {
      return {
        response: snapshot.val(),
        error: null,
        statusCode: "200"
      }
    })
      .catch((err) => {
        return {
          response: null,
          error: err.message,
          statusCode: this.getErrorCode(err.code)
        }
      })
  }

  async getDetailsByCountryAndLanguage(countryIso, language) {
    let iso = String(countryIso).toUpperCase()
    return database.ref(`/countryDataDetails/${iso}/${language}`).once("value").then((snapshot) => {
      if (!snapshot.val()) {
        return database.ref(`/countryDataDetails/${iso}/en`).once("value").then((snapshot) => {
          return {
            response: new CountryData(snapshot.val()),
            error: null,
            statusCode: "200"
          }
        })
          .catch((err) => {
            return {
              response: null,
              error: err.message,
              statusCode: this.getErrorCode(err.code)
            }
          })
      } else {
        return {
          response: new CountryData(snapshot.val()),
          error: null,
          statusCode: "200"
        }
      }
    })
      .catch((err) => {
        return {
          response: null,
          error: err.message,
          statusCode: this.getErrorCode(err.code)
        }
      })
  }

  async verifyTokenExistence(token) {
    return database.ref(`/authTokens/travelSafetyToken/${token}`).once("value").then((snapshot) => {
      if (snapshot.val().consumedTs <= new Date().getTime()) {
        return {
          response: false,
          error: null,
          statusCode: "200"
        };
      } else {
        return {
          response: true,
          error: null,
          statusCode: "200"
        };
      }
    })
      .catch((err) => {
        return {
          response: false,
          error: err.message,
          statusCode: this.getErrorCode(err.code),
        };
      })
  }

  async consumeToken(token) {
    database.ref(`/authTokens/travelSafetyToken/${token}`).update({
      consumedTs: new Date().getTime()
    })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  }

  //I didn't really found a satisfactory answer on status codes by Firebase
  getErrorCode(errorCode) {
    if (errorCode === "OPERATION_FAILED" || errorCode === "UNAVAILABLE" || errorCode === "UNKNOWN_ERROR") {
      return "500";
    } else {
      return errorCode;
    }
  }

  async getCurrencies() {
    return database.ref(`/currencyExchangeRates`).once("value").then((snapshot) => {
      return {
        response: new CurrencyModel(snapshot.val()),
        error: null,
        statusCode: "200"
      }
    })
      .catch((err) => {
        return {
          response: null,
          error: err.message,
          statusCode: this.getErrorCode(err.code)
        }
      })
  }
}