import * as React from "react";
import "./section.css";

export type SectionTitleProps = {
  title: String,
  iconName: any
}

export const SectionTitle = (props: SectionTitleProps) => {
  return (
    <div className="sectionTitle-main">
      <i className={`ph ph-${props.iconName}`}/>
      <div className="sectionTitle-text h4-bold">{props.title}</div>
    </div>
  )
}