export const it = {
  travelSafety: "Sicurezza in viaggio",
  health: "Salute",
  remember: "Ricorda",
  summary: "Sommario",
  vaccination: "Vaccinazione",
  advisory: "Consultivo",
  readMore: "Leggi di più.",
  covid19: "COVID-19",
  updated: "Aggiornato",
  total: "Totale",
  yesterday: "Ieri",
  cases: "Casi",
  deaths: "Deceduti",
  recovered: "Recuperato",
  casesPerMillion: "Casi per milione",
  testsPerMillion: "Test per milione",
  population: "Popolazione",
  checklist: "Lista di controllo",
  importInApp: "Importa nell'app",
  advisoryDescription: "Potrebbero essere applicate restrizioni di viaggio a causa della pandemia COVID-19.",
  noCovidData: "Nessun dato disponibile in questo momento.",
  infoForTravelersTo: "Informazioni per chi si reca nel seguente paese: [COUNTRY_NAME].",
  enjoyHolidayWith: "Goditi la tua vacanza con ",
  readySetHoliday: "Pronti, partenza, vacanza",
  serverErrorText: "Siamo spiacenti, si è verificato un errore durante l'accesso a queste informazioni.",
  notFoundText: "Siamo spiacenti, le informazioni richieste non sono state trovate.",

  // new texts
  enterValue: "Inserisci il valore",
  exchangeRate: "Tasso di cambio",
  // Tab 1 Good to Know
  goodToKnow: "Buono a sapersi",
  whenToVisit: "Quando visitare",
  visaAndEntry: "Visto e requisiti di ingresso",
  officialInformation: "Informazioni ufficiali",
  officialInfoText: "Controlla il sito web ufficiale del turismo governativo per le linee guida di vi aggiornate.",
  currency: "Valuta",
  localCurrency: "La valuta locale è ",
  emergencyServices: "Servizi di emergenza",
  electricity: "Elettricità",
  transport: "Trasporto",
  safety: "Sicurezza",
  internetAndConnectivity: "Internet e connettività",
  timeZone: "Fuso orario",
  language: "Lingua",
  localLawsAndRegulation: "Leggi e regolamenti locali",

  //Tab 2 Health
  waterQuality: "Qualità dell'acqua",
  vaccinations: "Vaccinazioni",
  insurance: "Assicurazione",
  insuranceText: "Si consiglia di stipulare un'assicurazione di viaggio per coprire eventuali spese mediche impreviste o interruzioni del viaggio.",
  medicalChecklist: "Checklist medica",

  // Tab 3 Cultural
  cultural: "Culturale",
  culturalNorms: "Norme culturali",
  shopping: "Acquisti",
  prices: "Prezzi",
  tipping: "Mancia",
  localCuisine: "Cucina locale",
  weather: "Meteo",
  location: "Posizione",
  
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}