export const nl = {
  travelSafety: "Reisveiligheid",
  health: "Gezondheid",
  remember: "Onthouden",
  summary: "Overzicht",
  vaccination: "Vaccinatie",
  advisory: "Advies",
  readMore: "Lees verder.",
  covid19: "COVID-19",
  updated: "Bijgewerkt",
  total: "Totaal",
  yesterday: "Gisteren",
  cases: "Gevallen",
  deaths: "Sterfgevallen",
  recovered: "Hersteld",
  casesPerMillion: "Gevallen per miljoen",
  testsPerMillion: "Tests per miljoen",
  population: "Bevolking",
  checklist: "Checklist",
  importInApp: "Importeer in app",
  advisoryDescription: "Vanwege de COVID-19 pandemie kunnen reisbeperkingen van toepassing zijn.",
  noCovidData: "Er zijn momenteel geen gegevens beschikbaar.",
  infoForTravelersTo: "Informatie voor mensen die naar [COUNTRY_NAME] reizen ",
  enjoyHolidayWith: "Geniet van uw vakantie met ",
  readySetHoliday: "Ready Set Holiday! Versie",
  serverErrorText: "Het spijt ons, er is een fout opgetreden bij het openen van deze informatie.",
  notFoundText: "Het spijt ons, maar de gevraagde informatie is niet gevonden.",

  // new texts
  enterValue: "Voer waarde in",
  exchangeRate: "Wisselkoers",
  // Tab 1 Good to Know
  goodToKnow: "Goed om te weten",
  whenToVisit: "Wanneer te bezoeken",
  visaAndEntry: "Visum & Toegangseisen",
  officialInformation: "Officiële informatie",
  officialInfoText: "Controleer de officiële overheidswebsite voor toerisme voor actuele reisrichtlijnen en informatie.",
  currency: "Valuta",
  localCurrency: "De lokale valuta is ",
  emergencyServices: "Nooddiensten",
  electricity: "Elektriciteit",
  transport: "Vervoer",
  safety: "Veiligheid",
  internetAndConnectivity: "Internet & Connectiviteit",
  timeZone: "Tijdzone",
  language: "Taal",
  localLawsAndRegulation: "Lokale wetten en regelgeving",

  //Tab 2 Health
  waterQuality: "Waterkwaliteit",
  vaccinations: "Vaccinaties",
  insurance: "Verzekering",
  insuranceText: "Het wordt aanbevolen om een reisverzekering af te sluiten om onverwachte medische kosten of reisonderbrekingen te dekken.",
  medicalChecklist: "Medische checklist",

  // Tab 2 Health
  cultural: "Cultureel",
  culturalNorms: "Culturele normen",
  shopping: "Winkelen",
  prices: "Prijzen",
  tipping: "Fooi",
  localCuisine: "Lokale keuken",
  weather: "Weer",
  location: "Locatie",
  
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}