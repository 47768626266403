import { keys } from "../../models/CountryData";

export const getPageData = (t, data) => {
  return [
    {
      title: t("culturalNorms"),
      text: data ? data[keys.cultural] : null,
      icon: "castle-turret",
      visibility: data ? !!data[keys.cultural] : false,
    },
    {
      title: t("shopping"),
      text: data ? data[keys.shopping] : null,
      icon: "shopping-cart-simple",
      visibility: data ? !!data[keys.shopping] : false,
    },
    {
      title: t("prices"),
      text: data ? data[keys.prices] : null,
      icon: "money",
      visibility: data ? !!data[keys.prices] : null,
    },
    {
      title: t("tipping"),
      text: data ? data[keys.tipping] : null,
      icon: "calculator",
      visibility: data ? !!data[keys.tipping] : null
    },
    {
      title: t("localCuisine"),
      text: data ? data[keys.cuisine] : null,
      icon: "fork-knife",
      visibility: data ? !!data[keys.cuisine] : null,
    },
    {
      title: t("weather"),
      text: data ? data[keys.weather] : null,
      icon: "umbrella-simple",
      visibility: data ? data[keys.weather] : null
    },
    {
      title: t("Location"),
      text: data ? data[keys.location] : null,
      icon: "flag",
      visibility: data ? !!data[keys.location] : null,
    },
  ]
}