
export const convert = (amount) => {
  let currencyModel = JSON.parse(window.sessionStorage.getItem("currency"));
  if (currencyModel.homeCurrency === currencyModel.destinationCurrency) {
    return Number(amount);
  }
  if (currencyModel.homeCurrency === "EUR") {
    return amount * (1 / currencyModel.destinationRate);
  }
  if (currencyModel.destinationCurrency === "EUR") {
    return amount * currencyModel.homeRate;
  }
  return amount * (currencyModel.homeRate / currencyModel.destinationRate);
}
