import { keys } from "../../models/CountryData";

export const getPageData = (t, data, countryData) => {
  return [
    {
      title: t("whenToVisit"),
      text: data ? data[keys.when] : null,
      icon: "calendar-check",
      visibility: data ? !!data[keys.when] : false,
    },
    {
      title: t("visaAndEntry"),
      text: data ? data[keys.visa] : null,
      icon: "identification-card",
      visibility: data ? !!data[keys.visa] : false,
    },
    {
      title: t("officialInformation"),
      text: t("officialInfoText"),
      icon: "info",
      readMoreUrl: countryData ? countryData["official_travel_URL"] : null,
      visibility: true //it is always visible
    },
    {
      title: t("currency"),
      text: data ? data[keys.currency] : null,
      icon: "coins",
      type: "currency-exchange",
      visibility: true,
    },
    {
      title: t("emergencyServices"),
      text: data ? data[keys.emergency] : null,
      icon: "first-aid-kit",
      visibility: data ? !!data[keys.emergency] : false,
    },
    {
      title: t("electricity"),
      text: data ? data[keys.electricity] : null,
      icon: "lightning",
      image: getPlugTypeImage(data ? data[keys.electricity] : null),
      visibility: data ? !!data[keys.electricity] : false,
    },
    {
      title: t("transport"),
      text: data ? data[keys.transport] : null,
      icon: "bus",
      visibility: data ? !!data[keys.transport] : false,
    },
    {
      title: t("safety"),
      text: data ? data[keys.safety] : null,
      icon: "shield-check",
      visibility: data ? !!data[keys.safety] : false,
    },
    {
      title: t("internetAndConnectivity"),
      text: data ? data[keys.internet] : null,
      icon: "globe-simple",
      visibility: data ? !!data[keys.internet] : false
    },
    {
      title: t("timeZone"),
      text: data ? data[keys.timeZone] : null,
      icon: "clock",
      visibility: data ? !!data[keys.timeZone] : false
    },
    {
      title: t("language"),
      text: data ? data[keys.language] : null,
      icon: "translate",
      visibility: data ? !!data[keys.language] : false
    },
    {
      title: t("localLawsAndRegulation"),
      text: data ? data[keys.laws] : null,
      icon: "scales",
      visibility: data ? !!data[keys.laws] : false
    }
  ]
}

const getPlugTypeImage = (data) => {
  if (!data) return null;
  let regEx = /[A-L]\b/g;
  let match = data.match(regEx);
  if (!match) return null;
  match = match.map(item => `type${item}`);
  return match;
}