export const es = {
  travelSafety: "Seguridad de viaje",
  health: "Salud",
  remember: "Recuerda",
  summary: "Resumen",
  vaccination: "Vacunación",
  advisory: "Consultivo",
  readMore: "Lee mas.",
  covid19: "COVID-19",
  updated: "Actualizado",
  total: "Total",
  yesterday: "Ayer",
  cases: "Casos",
  deaths: "Fallecidos",
  recovered: "Recuperado",
  casesPerMillion: "Casos por millón",
  testsPerMillion: "Puebas por millón",
  population: "Población",
  checklist: "Lista de Verificación",
  importInApp: "Importar en la aplicación",
  advisoryDescription: "Es posible que se apliquen restricciones de viaje debido a la pandemia de COVID-19.",
  noCovidData: "No hay datos disponibles en este momento.",
  infoForTravelersTo: "Información para personas que viajen al siguiente país: [COUNTRY_NAME].",
  enjoyHolidayWith: "Disfruta tus vacaciones con ",
  readySetHoliday: "Ready Set Holiday!",
  serverErrorText: "Lo sentimos, hubo un error al acceder a esta información.",
  notFoundText: "Lo sentimos, no se encontró la información solicitada.",

  // new texts
  enterValue: "Introducir valor",
  exchangeRate: "Tipo de cambio",
  // Tab 1 Good to Know
  goodToKnow: "Bueno saber",
  whenToVisit: "Cuando visitar",
  visaAndEntry: "Visa y requisitos de entrada",
  officialInformation: "Información oficial",
  officialInfoText: "Consulte el sitio web oficial de turismo del gobierno para obtener las pautas de viaje actuales.",
  currency: "Moneda",
  localCurrency: "La moneda local es ",
  emergencyServices: "Servicios de emergencia",
  electricity: "Electricidad",
  transport: "Transporte",
  safety: "Seguridad",
  internetAndConnectivity: "Internet y conectividad",
  timeZone: "Zona horaria",
  language: "Idioma",
  localLawsAndRegulation: "Leyes y regulaciones locales",

  //Tab 2 Health
  waterQuality: "Calidad del agua",
  vaccinations: "Vacunas",
  insurance: "Seguro",
  insuranceText: "Se recomienda contratar un seguro de viaje para cubrir cualquier gasto médico inesperado o interrupciones en el viaje.",
  medicalChecklist: "Lista de verificación médica",

  // Tab 3 Cultural
  cultural: "Cultural",
  culturalNorms: "Normas culturales",
  shopping: "Compras",
  prices: "Precios",
  tipping: "Propina",
  localCuisine: "Cocina local",
  weather: "Clima",
  location: "Ubicación",

  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}