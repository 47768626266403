export const pt = {
  travelSafety: "Segurança em viagens",
  health: "Saúde",
  remember: "Lembrar",
  summary: "Resumo",
  vaccination: "Vacinação",
  advisory: "Consultivo",
  readMore: "Consulte Mais informação.",
  covid19: "COVID-19",
  updated: "Atualizada",
  total: "Total",
  yesterday: "Ontem",
  cases: "Casos",
  deaths: "Mortes",
  recovered: "Recuperado",
  casesPerMillion: "Casos por milhão",
  testsPerMillion: "Testes por milhão",
  population: "População",
  checklist: "Lista de verificação",
  importInApp: "Importar no aplicativo",
  advisoryDescription: "Restrições de viagens podem ser aplicadas devido à pandemia COVID-19.",
  noCovidData: "Nenhum dado disponível no momento.",
  infoForTravelersTo: "Informações para pessoas que viajam para o seguinte país: [COUNTRY_NAME]",
  enjoyHolidayWith: "Aproveite suas férias com ",
  readySetHoliday: "Aos seus lugares, prontos? Férias!",
  serverErrorText: "Lamentamos, ocorreu um erro ao acessar essas informações.",
  notFoundText: "Lamentamos, a informação solicitada não foi encontrada.",

  // new texts
  enterValue: "Insira o valor",
  exchangeRate: "Taxa de câmbio",
  // Tab 1 Good to Know
  goodToKnow: "Bom saber",
  whenToVisit: "Quando visitar",
  visaAndEntry: "Visto e requisitos de entrada",
  officialInformation: "Informação oficial",
  officialInfoText: "Verifique o site oficial de turismo do governo para diretrizes de viagem atuais e informações oficiais.",
  currency: "Moeda",
  localCurrency: "A moeda local é ",
  emergencyServices: "Serviços de emergência",
  electricity: "Eletricidade",
  transport: "Transporte",
  safety: "Segurança",
  internetAndConnectivity: "Internet e conectividade",
  timeZone: "Fuso horário",
  language: "Idioma",
  localLawsAndRegulation: "Leis e regulamentos locais",

  //Tab 2 Health
  waterQuality: "Qualidade da água",
  vaccinations: "Vacinação",
  insurance: "Seguro",
  insuranceText: "É recomendável contratar um seguro de viagem para cobrir despesas médicas inesperadas ou interrupções na viagem.",
  medicalChecklist: "Lista de verificação médica",

  // Tab 3 Cultural
  cultural: "Cultural",
  culturalNorms: "Normas culturais",
  shopping: "Compras",
  prices: "Preços",
  tipping: "Gorjeta",
  localCuisine: "Cozinha local",
  weather: "Tempo",
  location: "Localização",
  
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}