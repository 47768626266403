import * as React from "react";
import PulseLoader from "react-spinners/PulseLoader";

export const Spinner = ({className}) => {
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "#f3681a",
  };

  return (
    <PulseLoader
      color={"#f3681a"}
      className={className}
      loading={true}
      cssOverride={override}
      size={7}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  )
}