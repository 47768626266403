import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from "../../components/section/Section";
import { analyticsService } from "../../services/AnalyticsService";
import { getPageData } from "./pageData";

/**
 * Cultural tab
 */
export function Cultural({ countryData }) {
  const { t } = useTranslation('translation');

  useEffect(() => {
    //analytics here
    if (process.env.REACT_APP_ENV === "prod") {
      analyticsService().trackPage("websiteVisit", {
        fullUrl: window.location.href,
        pageName: "Cultural page - Cultural tab"
      });
    }
  }, []);

  return (
    <div>
      <span className="spam-40" />
      {
        getPageData(t, countryData).map((item, index) => {
          return (item.visibility &&
            <React.Fragment>
              <Section
                key={index}
                iconName={item.icon}
                title={item.title}
                text={item.text}
                image={item.image}
              />
              <span className="spam-40" />
            </React.Fragment>
          )
        })
      }
    </div>
  )
}


