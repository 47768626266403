import * as React from "react";
import { useTranslation } from 'react-i18next';
import "./navigation.css";
import { Tab } from "./Tab";
import { tabs } from "./TabConstants";

export type NavigationTabProps = {
  url: String,
  validCultureData: boolean
}

export const NavigationTab = (props: NavigationTabProps) => {
  const { t } = useTranslation('translation');
  let isActiveTab1 = window.location.href.includes(tabs.goodToKnow.routeName)
  let isActiveTab2 = window.location.href.includes(tabs.health.routeName)
  let isActiveTab3 = window.location.href.includes(tabs.cultural.routeName)

  React.useEffect(() => {
    if (isActiveTab1) {
      document.getElementById(tabs.goodToKnow.routeName).scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "end" });
    }
    if (isActiveTab2) {
      document.getElementById(tabs.health.routeName).scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "end" });
    }
    if (isActiveTab3) {
      document.getElementById(tabs.cultural.routeName).scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "end" });
    }
  }, [isActiveTab1, isActiveTab2, isActiveTab3]);

  return (
    <nav className="nav-tab-main">
      <div id={tabs.goodToKnow.routeName}>
        <Tab
          isEnabled={true}
          name={t(tabs.goodToKnow.name)}
          url={props.url}
          routeName={tabs.goodToKnow.routeName} />
      </div>
      <div id={tabs.health.routeName}>
        <Tab
          isEnabled={true}
          name={t(tabs.health.name)}
          url={props.url}
          routeName={tabs.health.routeName} />
      </div>
      {//only show this tab if the country has cultural data
        !!props.validCultureData &&
        <div id={tabs.cultural.routeName}>
          <Tab
            isEnabled={true}
            name={t(tabs.cultural.name)}
            url={props.url}
            routeName={tabs.cultural.routeName} />
        </div>
      }
    </nav>
  )
}

