//Counry Data Details
export class CountryData {
  goodToKnow = {};
  health = {};
  cultural = {};

  constructor(response) {
    this.goodToKnow = this.getDataAsObject(response, sectionKeys.goodToKnow);
    this.health = this.getDataAsObject(response, sectionKeys.health);
    this.cultural = this.getDataAsObject(response, sectionKeys.cultural);
  }

  getDataAsObject(response, sectionKeys) {
    let obj = {};
    sectionKeys.forEach(key => {
      if (response[key]) {
        obj = {
          ...obj,
          [key]: response[key]
        }
      }
    });
    obj = {
      ...obj,
      length: Object.keys(obj).length
    }
    return obj;
  }

}

export const keys = {
  //GOOD TO KNOW
  when: "when",
  visa: "visa",
  currency: "currency",
  emergency: "emergency",
  electricity: "electricity",
  transport: "transport",
  safety: "safety",
  internet: "internet",
  timeZone: "timezone",
  language: "language",
  laws: "laws",
  //HEALTH
  healthSummary: "health_summary",
  vaccination: "vaccination",
  water: "water",
  insurance: "insurance",
  //CULTURAL
  cultural: "cultural",
  shopping: "shopping",
  prices: "prices",
  tipping: "tipping",
  cuisine: "cuisine",
  weather: "weather",
  location: "location"
}

export const sectionKeys = {
  goodToKnow: [keys.when, keys.visa, keys.currency, keys.emergency, keys.electricity, keys.transport, keys.safety, keys.internet, keys.timeZone, keys.language, keys.laws],
  health: [keys.healthSummary, keys.vaccination, keys.water, keys.insurance],
  cultural: [keys.cultural, keys.shopping, keys.prices, keys.tipping, keys.cuisine, keys.weather, keys.location]
}

