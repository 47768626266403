export const fr = {
  travelSafety: "Sécurité de voyage",
  health: "Santé",
  remember: "Rappelles toi",
  summary: "Sommaire",
  vaccination: "Vaccination",
  advisory: "Consultatif",
  readMore: "Lire la suite.",
  covid19: "COVID-19",
  updated: "Mis à jour",
  total: "Total",
  yesterday: "Hier",
  cases: "Cas",
  deaths: "Décès",
  recovered: "Rétabli",
  casesPerMillion: "Cas par million",
  testsPerMillion: "Tests par million",
  population: "Population",
  checklist: "Liste de contrôle",
  importInApp: "Importer dans l'application",
  advisoryDescription: "Des restrictions de voyage peuvent s'appliquer en raison de la pandémie de COVID-19.",
  noCovidData: "Aucune donnée disponible pour le moment.",
  infoForTravelersTo: "Informations pour les personnes voyageant vers le pays suivant: [COUNTRY_NAME]",
  enjoyHolidayWith: "Profitez de vos vacances avec ",
  readySetHoliday: "A vos marques, prêts, vacances!",
  serverErrorText: "Nous sommes désolés, une erreur s'est produite lors de l'accès à ces informations.",
  notFoundText: "Nous sommes désolés, les informations que vous avez demandées n'ont pas été trouvées.",

  // new texts
  enterValue: "Entrez la valeur",
  exchangeRate: "Taux de change",
  // Tab 1 Good to Know
  goodToKnow: "Bon à savoir",
  whenToVisit: "Quand visiter",
  visaAndEntry: "Visa et exigences d'entrée",
  officialInformation: "Information officielle",
  officialInfoText: "Consultez le site Web officiel du tourisme gouvernemental pour obtenir les directives de voyage actuelles.",
  currency: "Devise",
  localCurrency: "La devise locale est ",
  emergencyServices: "Services d'urgence",
  electricity: "Electricité",
  transport: "Transport",
  safety: "Sécurité",
  internetAndConnectivity: "Internet et connectivité",
  timeZone: "Fuseau horaire",
  language: "Langue",
  localLawsAndRegulation: "Lois et réglementations locales",

  //Tab 2 Health
  waterQuality: "Qualité de l'eau",
  vaccinations: "Vaccinations",
  insurance: "Assurance",
  insuranceText: "Il est recommandé de souscrire une assurance voyage pour couvrir les frais médicaux inattendus ou les interruptions de voyage.",
  medicalChecklist: "Liste de contrôle médicale",

  // Tab 3 Cultural
  cultural: "Culturel",
  culturalNorms: "Normes culturelles",
  shopping: "Achats",
  prices: "Prix",
  tipping: "Pourboire",
  localCuisine: "Cuisine locale",
  weather: "Météo",
  location: "Emplacement",
  
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}