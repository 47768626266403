import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from "../../components/section/Section";
import { Covid } from "../../components/covid/Covid";
import { HealthChecklist } from "../../components/healthChecklist/HealthChecklist"

import { CovidStatsForCountry } from "../../models/CovidStatsForCountry";
import { analyticsService } from "../../services/AnalyticsService";

import { getPageData } from "./pageData";

/**
 * Health tab
 * @param covidData = Data from the Covid API
 * @param data = Data fetched from the Firebase database
 */
export function Health({ isFinishedCovid, covidData, countryData, countryDataDetails }) {
  const { t, i18n } = useTranslation('translation');
  let lang = i18n.language.length > 2 ? "en" : i18n.language;

  useEffect(() => {
    //analytics here
    if (process.env.REACT_APP_ENV === "prod") {
      analyticsService().trackPage("websiteVisit", {
        fullUrl: window.location.href,
        pageName: "TravelSafety page - Health tab"
      });
    }
  }, []);

  let _covidData = new CovidStatsForCountry(covidData && covidData.payload || {}, lang)

  return (
    <div>
      <span className="spam-40" />
      {
        getPageData(t, countryDataDetails, countryData).map((item, index) => {
          return (item.visibility &&
            <React.Fragment>
              <Section
                key={index}
                iconName={item.icon}
                title={item.title}
                text={item.text}
                image={item.image}
              />
              <span className="spam-40" />
            </React.Fragment>
          )
        })
      }
      <HealthChecklist />
      <Covid
        iconName={"virus"}
        title={t("covid19")}
        data={_covidData}
        isLoading={!isFinishedCovid}
      />
      <span className="spam-40" />
    </div>
  )
}


